export default [
	{
		header: 'Pages',
		icon: 'FileIcon',
		children: [
			{
				title: 'home',
				route: 'home',
				icon:  'HomeIcon',
			},
		],
	},
]
